import React, { Component } from "react"
import Layout from "../../components/layout/Layout"
import PageHelmet from "../../components/Helmet"
import BGImage from "gatsby-background-image"

class ServicesStrategy extends Component {
    render() {
        return(
            <>
                <PageHelmet pageTitle='Services > Digital Strategy' />
                <Layout>
                    {/* Start Banner Area */}
                    <BGImage
                        fluid={this.props.data.headerBackgroundImage.childImageSharp.fluid}>
                        <div className="rn-page-title-area pt--120 pb--190"  data-black-overlay="8">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="rn-page-title text-center pt--100">
                                            <h2 className="title theme-gradient">DIGITAL STRATEGY</h2>
                                            <p>Roninworks is Your One-Stop-Shop</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </BGImage>
                    {/* End Banner Area */}
                </Layout>
            </>
        )
    }
}

export default ServicesStrategy

export const query = graphql`
    query {
        headerBackgroundImage: file(relativePath: { eq: "services-strategy-banner.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`